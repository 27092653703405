import React, { useState, useEffect } from 'react'

const EzoicAd = ({ id }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<div style='display:flex;align-items:center;width:100%;justify-content:center;'><div id="ezoic-pub-ad-placeholder-${id}"></div></div>`
      }}
      suppressHydrationWarning
    />
  )
}

export default EzoicAd
