import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Box, Flex, DesktopContainer } from './ui/BuildingBlocks'
import Button from './ui/Button'
import Spinner from './ui/Spinner'
import { colors, smallBoxShadow } from './ui/helpers.js'
import { urlToString } from './ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { translate, countryTranslate, activeCountries } from '../../constants/translations'
import { H1, H2, H3, SeoH2, SeoH4, Span, Text, LinkSpan } from './ui/Font'
import OnlineIcon from './ui/OnlineIcon'
import EzoicAd from './EzoicAd'

const TelephoneContainer = styled(Flex)`
  background-color: ${(props) => (props.isDark ? colors.lightGrey : colors.lightGrey)};
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  flex-direction: column;
  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
`

const DesktopSideBarContainer = styled(DesktopContainer)`
  flex-direction: column;
  width: 350px;
  min-width: 350px;
  background-color: ${colors.lighterGrey};
  padding-left: 24px;
  padding-top: 20px;
  padding-bottom: 32px;
  padding-right: 24px;
`

const FlagImage = styled.img`
  border-radius: 8px;
  margin-right: 16px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="30px" height="30px" />
}

const DesktopSideBar = ({ iso2, telephone, countryTelephones, country, telephones }) => {
  if (countryTelephones.length === 0) {
    return null
  }
  return (
    <DesktopSideBarContainer>
      <EzoicAd id="109" />
      <Text fontWeight="bold" lineHeight="34px" fontSize="30px" mb={4}>
        {translate(`Other ${urlToString(country)} Numbers:`, 'otherCountryNumbers', iso2, {
          country: urlToString(countryTranslate(country, iso2))
        })}
      </Text>
      {countryTelephones.map((telephone, i) => {
        return (
          <>
            <a key={telephone} href={`${iso2 === 'en' ? '' : `/${iso2}`}/${countryTranslate(country, iso2)}/${telephone}/`}>
              <TelephoneContainer>
                <Flex alignItems="center">
                  <Flag iso2={countryToIso2[country]} />
                  <Text width="100%" fontSize="26px" textAlign="right" mr={2}>
                    +{telephone}
                  </Text>
                  <OnlineIcon />
                </Flex>
              </TelephoneContainer>
            </a>
            {/* {i === 1 ? (
              <EzoicAd id="110" />
            ) : // <>
            //   <ins
            //     className="adsbygoogle"
            //     style={{ display: 'block', marginBottom: '8px', width: '300px', height: '250px' }}
            //     data-ad-client="ca-pub-3580818377622359"
            //     data-ad-slot="6733692757"
            //     data-ad-format="auto"
            //     data-full-width-responsive="true"
            //   ></ins>
            // </>
            null}
            {i === 4 ? (
              <EzoicAd id="111" />
            ) : // <>
            //   <ins
            //     className="adsbygoogle"
            //     style={{ display: 'block', marginBottom: '8px', width: '300px', height: '250px' }}
            //     data-ad-client="ca-pub-3580818377622359"
            //     data-ad-slot="2273509856"
            //     data-ad-format="auto"
            //     data-full-width-responsive="true"
            //   ></ins>
            // </>
            null} */}
          </>
        )
      })}
      {/* {Object.keys(telephones).map((otherCountry) => {
        if (otherCountry === country) {
          return null
        }
        return (
          <Box key={otherCountry}>
            <Text fontWeight="bold" lineHeight="34px" fontSize="30px" mt={4} mb={4}>
              {urlToString(otherCountry)} Numbers:
            </Text>
            {telephones[otherCountry].map((telephone, i) => {
              return (
                <Link key={telephone} to={`/${otherCountry}/${telephone}/`}>
                  <TelephoneContainer>
                    <Flex alignItems="center">
                      <Flag iso2={countryToIso2[otherCountry]} />
                      <Text width="100%" fontSize="26px" textAlign="right" mr={2}>
                        +{telephone}
                      </Text>
                      <OnlineIcon />
                    </Flex>
                    <Text fontSize="12px" textAlign="right">
                      Last message : {Math.round(Math.random() * 5)} mins ago
                    </Text>
                  </TelephoneContainer>
                </Link>
              )
            })}
          </Box>
        )
      })} */}
    </DesktopSideBarContainer>
  )
}
export default DesktopSideBar

//http://localhost:8000/united-kingdom/447901614024
// d1uyh0a3eznojw.cloudfront.net/united-kingdom/447901614024
